import React, { useEffecrt, useEffect, useState, useCallback } from "react";
import useSWR from "swr";
import Numeral from "numeral";
import _groupBy from "lodash/groupBy";
import _ from "lodash";
import Ax from "axios";
import { connect, useDispatch } from "react-redux";
import { postFetcher, postDispatcher } from "../../utils/fetch";
import { CSVLink } from "react-csv";

import ConvertActions from "../../actions/ConvertClicksActions";
import { debounce } from "lodash";
import { NumberInput } from "grommet-controls";
import styled from "styled-components";
import FiltersV4 from "../../components/FiltersV4";

import {
  Box,
  Grommet,
  Button,
  Form,
  List,
  Tip,
  Text,
  Layer,
  Spinner,
  Heading,
  CheckBox,
  TextInput,
  Select,
  TextArea,
  FormField,
  DataTable,
  DateInput,
} from "grommet";
import { Search, Add, Send, Copy, View, Download, Filter } from "grommet-icons";
import { minimalTheme } from "../../themes/main-theme";
import Moment from "moment";
// import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";
import { useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";

function mergeData(data) {
  const mergedMap = new Map();

  if (!data) return [];

  data.forEach((item) => {
    const key = `${item.afid}-${item.event_time}`;

    if (mergedMap.has(key)) {
      const existingItem = mergedMap.get(key);

      // Merge the revenue, cost, profit, areg, aftd, preg, and pftd
      existingItem.revenue += item.revenue || 0;
      existingItem.cost += item.cost || 0;
      existingItem.profit += item.profit || 0;

      existingItem.areg += item.areg || 0;
      existingItem.aftd += item.aftd || 0;
      existingItem.preg += item.preg || 0;
      existingItem.pftd += item.pftd || 0;

      // Optionally, merge any other fields if needed
    } else {
      // Add a new entry in the map
      mergedMap.set(key, { ...item });
    }
  });

  // Convert the merged map back to an array
  return Array.from(mergedMap.values());
}

const agencies = {
  ALL: -1,
  CYGOBELMEDIA: 0,
  MOBLICA: 1,
  CLEARPIERPER: 2,
  KPMBROMEDIA: 3,
  VIDOMO: 4,
  LOPOEMEDIA: 5,
};

const agenciesOpts = [
  { label: "CYGOBELMEDIA", value: "0" },
  { label: "MOBLICA", value: "1" },
  { label: "CLEARPIER", value: "2" },
  { label: "KPMBROMEDIA", value: "3" },
  { label: "VIDOMO", value: "4" },
  { label: "LOPOEMEDIA", value: "5" },
];

function AfapiRawFinance(props) {
  const { afRawFinance } = props;
  const {
    dateFrom,
    dateTo,
    datePreset,
    publishers,
    agencies,
    pubgroups,
    countries,
    platforms,
    campaigns,
    channels,
    apps,
    withValue,
    hideInstalls,
    showDupes,
  } = afRawFinance.filters;
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const source = "FINANCE";

  useEffect(() => {
    // Run! Like go get some data from an API.
    postDispatcher(
      "/api/appsflyer/agency-raw-entity-data",
      { dateFrom, dateTo, datePreset, source },
      dispatch
    );
    console.info("======= ONLY ONCE  OR DATE CHANGE======");
  }, [dispatch, dateTo, dateFrom, datePreset, source]);

  const asyncSearch = (search, query) => {
    postDispatcher(
      "/api/appsflyer/agency-raw-entity-data",
      { search, query, source },
      dispatch
    );
  };

  const debouncedaAyncSearch = useCallback(debounce(asyncSearch, 300), [
    asyncSearch,
  ]);

  const { data, error, mutate, isValidating } = useSWR(
    [
      `/api/appsflyer/agency-raw-data${window.location.search}`,
      {
        source: "FINANCE",
        dateFrom,
        dateTo,
        apps,
        publishers,
        agencies,
        pubgroups,
        platforms,
        campaigns,
        channels,
        countries,
        withValue,
        hideInstalls,
      },
    ],
    postFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  console.info({ data });
  // useEffect(() => {
  //   searchParams.set("withEventValue", "true");
  //   setSearchParams(searchParams);
  // }, []);

  // useEffect(() => {
  //   if (data && data[0]?.event_value) {
  //     if (tableColumns[6].property !== "event_value") {
  //       tableColumns.splice(6, 0, {
  //         property: "event_value",
  //         header: "Value",
  //         align: "center",
  //         render: ({ event_value }) => {
  //           return <div>{+event_value}</div>;
  //         },
  //       });
  //     }
  //   } else {
  //     if (tableColumns[6].property === "event_value") {
  //       tableColumns.splice(6, 1);
  //     }
  //   }
  // }, [data]);

  // console.info({afRawFinance});

  console.log(mergeData(data));

  const updateFilters = (name, value) => {
    if (!value) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    setSearchParams(searchParams);
  };

  return (
    <Grommet
      theme={{
        ...minimalTheme,
        text: {
          extend: "font-size:12px",
        },
      }}
      style={{ width: "100%", maxHeight: "100%", overflow: "scroll" }}
    >
      <Box>
        <FiltersV4
          state={afRawFinance.filters}
          // noDateInputs
          onFilterChange={(filters) => {
            console.info({ filters });
            dispatch({
              type: "AFAPI_RAW_FINANCE_FILTERS_CHANGE",
              filters,
            });
            // setFilters(f => ({ ...f, ...filters }))
          }}
          dynamicFilters={[
            {
              name: "Apps",
              opts: afRawFinance.apps,
              vals: afRawFinance.filters.apps || [],
            },
            { name: "Agencies", opts: agenciesOpts },
            {
              name: "Publishers",
              opts: afRawFinance.publishers,
              onAsyncSearch: ({ val }) =>
                debouncedaAyncSearch("publishers", val),
            },
            { name: "PubGroups", opts: afRawFinance.pubgroups },
            { name: "Campaigns", opts: afRawFinance.campaigns },
            { name: "Channels", opts: afRawFinance.channels },
            { name: "Platforms", opts: afRawFinance.platforms },
            { name: "Countries", opts: afRawFinance.countries },
          ]}
          dynamicBooleans={[
            { name: "With Value", key: "withValue", value: withValue },
            { name: "Hide Installs", key: "hideInstalls", value: hideInstalls },
            { name: "Show Dupes", key: "showDupes", value: showDupes },
          ]}
          dataInfo={
            data ? `${Numeral(data.length).format("0,0")} rows` : undefined
          }
          exportButtonCSV={
            <CSVLink
              data={mergeData(data)}
              filename={`Finance-${new Date().toISOString()}.csv`}
            >
              <Button
                style={{
                  padding: "2px 6px",
                  borderRadius: 5,
                }}
                primary
              >
                Export CSV
              </Button>
            </CSVLink>
          }
        />

        {data && data.length ? (
          <DataTable
            pin
            primaryKey={"id"}
            key={`afapi_raw_fin_table_${data ? data.length : 0}_${
              afRawFinance.filters.groupBy
            }`}
            columns={getTableColumns(data, {
              groupBy: afRawFinance.filters.groupBy,
              showDupes,
            })}
            // paginate
            placeholder={
              (data && !data.length && "No data found") ||
              (isValidating && (
                <Box>
                  <Spinner size='medium' message='Loading...' />
                </Box>
              ))
            }
            pad={{
              horizontal: "medium",
            }}
            background={{
              header: "dark-2",
              body: ["white", "light-2"],
              footer: { dark: "light-2", light: "dark-3" },
            }}
            sortable
            data={data}
            groupBy={afRawFinance.filters.groupBy || undefined}
          />
        ) : isValidating ? (
          <Box pad='small'>
            <Spinner size='medium' message='Loading...' />
          </Box>
        ) : undefined}
      </Box>
    </Grommet>
  );
}

const mapStateToProps = (state, props) => {
  const { afRawFinance } = state;

  return {
    afRawFinance,
  };
};

export default connect(mapStateToProps, { ...ConvertActions })(AfapiRawFinance);

/*
Raw data report 

Date = Event Time
Filter: Event type = Reg or FTD
Partner = Agency name 
Media Source = Publisher (PID) 
Channel = Channel (PubID_cost)
Column - Publisher - take the PubID from the Channel. check TrackRC who is the Pub -> write the pub name
Campaign = Campaign
Country Code
Platform (IOS & Android)
ClickID Starts with 11e

 Events: Reg & FTD 
Revenue 
Cost 
Profit 


*/

function calcGroupBySum(data, groupBy) {
  let groupBySum = null;
  if (groupBy) {
    groupBySum = _groupBy(data, groupBy);
    for (const groupEntity in groupBySum) {
      groupBySum[groupEntity] = groupBySum[groupEntity]
        .map((o) => o.profit)
        .reduce((a, b) => a + b);
    }
  }
  return groupBySum;
}

function checkGroupByRow(props, groupBy, groupBySum) {
  if (!groupBySum) return false;
  const { profit } = props;
  const groupVal = props[groupBy];
  const isGroupRow = groupBySum[groupVal] === profit;
  return isGroupRow;
}

function getDupesByGroupBy(data, groupBy) {
  if (!data || !data.length || !groupBy) return {};
  const dataGroups = _groupBy(data, groupBy);

  let dupesByGroupBy = {};

  for (const group in dataGroups) {
    // console.info({group})
    const groupData = dataGroups[group];
    // console.info(JSON.stringify(groupData))
    let dupesByGroup = _.chain(groupData)
      .groupBy(
        (p) =>
          `${p.source.toLowerCase() === "acq" ? "a" : "p"}${
            p.event_type === "ftd" ? "ftd" : "reg"
          }_${p.afid}`
      )
      .value();

    for (const dupe in dupesByGroup) {
      if (dupesByGroup[dupe].length === 1) {
        delete dupesByGroup[dupe];
      }
    }
    dupesByGroupBy[group] = dupesByGroup;
  }
  return dupesByGroupBy;
}

function renderCounterWithDupes(
  propKey,
  { groupBy, groupBySum, dupesByGroupBy }
) {
  return (props) => {
    const value = props[propKey];

    const isGroupByRow = checkGroupByRow(props, groupBy, groupBySum);

    let dupeCount = 0;
    let dupeIds = {};

    if (isGroupByRow) {
      if (dupesByGroupBy[props[groupBy]]) {
        for (const key in dupesByGroupBy[props[groupBy]]) {
          if (key.includes(propKey)) {
            dupeCount += dupesByGroupBy[props[groupBy]][key].length - 1;

            const id = key.split("_")[1];
            dupeIds[id] = dupesByGroupBy[props[groupBy]][key].length;
          }
        }
      }
    }

    return (
      <Cell>
        {value ? (
          dupeCount ? (
            <Tip content={JSON.stringify(dupeIds)}>
              <Box direction='row'>
                {value}{" "}
                <Text size='small' color='status-warning'>
                  ({dupeCount})
                </Text>
              </Box>
            </Tip>
          ) : (
            +value
          )
        ) : (
          "-"
        )}
      </Cell>
    );
  };
}

function getTableColumns(data, { groupBy, showDupes }) {
  let groupBySum = showDupes ? calcGroupBySum(data, groupBy) : calcGroupBySum(data, groupBy);

  console.info({groupBySum})
  
  const dupesByGroupBy = showDupes ? getDupesByGroupBy(data, groupBy) : {};

  const tableColumns = [
    // {
    //   property: 'id',
    //   header: "ID",
    //   // primary: true,
    //   // footer: 'Total',
    // },
    {
      property: "event_time",
      header: "Event",
      render: ({ event_time }) => {
        return (
          <Cell style={{ width: "max-content" }}>
            {Moment(event_time).format("MM-DD-YY HH:mm")}
          </Cell>
        );
      },
    },
    {
      property: "app_bundle",
      header: "App Bundle",
      render: ({ app_bundle }) => {
        return <Cell start>{app_bundle}</Cell>;
      },
    },
    {
      property: "app_name",
      header: "App Name",
      render: ({ app_name }) => {
        return <Cell start>{app_name}</Cell>;
      },
    },
    {
      property: "partner",
      header: "Agency",
      render: ({ partner }) => {
        return <Cell start>{partner}</Cell>;
      },
    },
    {
      property: "pid",
      header: "PID",
      render: ({ pid }) => {
        return <Cell start>{pid}</Cell>;
      },
    },
    {
      property: "channel",
      header: "Channel",
      render: ({ channel }) => {
        return <Cell start>{channel}</Cell>;
      },
    },
    {
      property: "ch_pub",
      header: "PubGroup",
      render: ({ ch_pub, ch_pubid }) => {
        return (
          <Cell style={{ textTransform: "uppercase" }} start>
            {ch_pub}
          </Cell>
        );
      },
    },
    {
      property: "country_code",
      header: "Country",
      align: "center",
      render: ({ country_code }) => {
        return <Cell>{country_code}</Cell>;
      },
    },
    {
      property: "platform",
      header: "Platform",
      align: "center",
      render: ({ platform }) => {
        return <Cell style={{ textTransform: "uppercase" }}>{platform}</Cell>;
      },
    },
    {
      property: "afid",
      header: "AFID",
      render: ({ afid }) => {
        return <Cell start>{afid}</Cell>;
      },
    },
    {
      property: "event_name",
      header: "Event Key",
      render: ({ event_name }) => {
        return (
          <Cell start style={{ width: "max-content" }}>
            {event_name}
          </Cell>
        );
      },
    },
    {
      property: "event_type",
      header: "Event Type",
      render: ({ event_type }) => {
        return <Cell start>{event_type}</Cell>;
      },
    },
    {
      property: "det_clickid",
      header: "CID",
      align: "center",
      render: ({ det_clickid }) => {
        return (
          <div style={{ display: "flex" }}>
            <Tip content={det_clickid}>
              <Button disabled={!det_clickid} icon={<View size='small' />} />
            </Tip>
            <Button
              disabled={!det_clickid}
              icon={<Copy size='small' />}
              onClick={() => navigator.clipboard.writeText(det_clickid)}
            />
          </div>
        );
      },
    },
    {
      property: "areg",
      header: "Reg UA",
      align: "center",
      aggregate: "sum",
      render: !showDupes
        ? undefined
        : renderCounterWithDupes("areg", {
            groupBy,
            groupBySum,
            dupesByGroupBy,
          }),
      footer: { aggregate: true },
    },
    {
      property: "preg",
      header: "Reg POST",
      align: "center",
      aggregate: "sum",
      render: !showDupes
        ? undefined
        : renderCounterWithDupes("preg", {
            groupBy,
            groupBySum,
            dupesByGroupBy,
          }),
      footer: { aggregate: true },
    },
    {
      property: "aftd",
      header: "FTD UA",
      align: "center",
      aggregate: "sum",
      render: !showDupes
        ? undefined
        : renderCounterWithDupes("aftd", {
            groupBy,
            groupBySum,
            dupesByGroupBy,
          }),
      footer: { aggregate: true },
    },
    {
      property: "pftd",
      header: "FTD POST",
      align: "center",
      aggregate: "sum",
      render: !showDupes
        ? undefined
        : renderCounterWithDupes("pftd", {
            groupBy,
            groupBySum,
            dupesByGroupBy,
          }),
      footer: { aggregate: true },
    },
    {
      property: "revenue",
      header: "Revenue",
      align: "center",
      aggregate: "sum",
      footer: { aggregate: true },
      render: ({ revenue }) => {
        return (
          <Cell type color='green'>
            {revenue ? Numeral(+revenue).format("0,0.00") : "-"}
          </Cell>
        );
      },
    },
    {
      property: "cost",
      header: "Cost",
      align: "center",
      aggregate: "sum",
      footer: { aggregate: true },
      render: ({ cost }) => {
        return (
          <Cell style={{ color: cost ? "red" : "" }}>
            {cost ? Numeral(+cost).format("0,0.00") : "-"}
          </Cell>
        );
      },
    },
    {
      property: "profit",
      header: "Profit",
      align: "center",
      aggregate: "sum",
      footer: { aggregate: true },
      render: (props) => {
        const { profit, revenue, cost } = props;
        return (
          <Cell
            // onCopy={(c) => console.info(c.target.innerText)}
            style={{ color: profit ? "blue" : "" }}
          >
            {
              // cost
              Numeral(profit).format("0,0.00")
            }
          </Cell>
        );
      },
    },

    // {
    //   property: "campaign",
    //   header: "Campaign",
    //   render: ({ campaign }) => {
    //     return <Cell start>{campaign}</Cell>;
    //   },
    // },
  ];

  return tableColumns;
}
